import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FaArrowLeft, FaEye } from "react-icons/fa";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AdminService from "../../services/admin_services";

class Patients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayPatients: this.props.patients,
            patient: null,
        }
    }

    headerPatient = () => {
        return (
            <input className="search" placeholder="Search" onChange={this.searchPatient} />
        )
    }

    footerPatient = () => {
        return (
            <p>{this.state.displayPatients.length} profiles referenced</p>
        )
    }

    searchPatient = (e) => {
        let displayPatients = [], filter = e.target.value.toLowerCase();
        for (let patient of this.props.patients) {
            if (patient.firstname.toLowerCase().includes(filter) || patient.lastname.toLowerCase().includes(filter) || patient.social_number.toLowerCase().includes(filter))
                displayPatients.push(patient)
        }
        this.setState({ displayPatients })
    }

    detailsPatient = (rowData, column) => {
        return (
            <button className="details" onClick={() => this.fetchPatient(rowData)}><FaEye/></button>
        )
    }

    fetchPatient = async(rowData) => {
        const patient = await AdminService.getPatient(rowData.patient_uuid)
        this.setState({patient})
    }

    render() {
        return (
            this.state.patient?
                <div className="profile">
                    <button className="details" onClick={() => this.setState({patient: null})}><FaArrowLeft/></button>
                    <div className="selfie" style={{backgroundImage: `url('${this.state.patient.selfie? this.state.patient.selfie : `https://api.dicebear.com/5.x/big-smile/jpg?seed=${this.state.patient.firstname}-${this.state.patient.lastname}`}')`}}></div>
                    <h3>{this.state.patient.firstname} {this.state.patient.lastname}</h3>
                    <label>Social number</label>
                    <h4 className="mt0"><strong>{this.state.patient.social_number}</strong></h4>
                    <label>Email</label>
                    <h4 className="mt0">{this.state.patient.email_address}</h4>
                    <label>Chain address</label>
                    <p className="mt0"><small>{this.state.patient.pk_hash}</small></p>
                    <label>Creation date</label>
                    <p className="mt0"><small>{new Date(this.state.patient.created_date).toLocaleDateString()} {new Date(this.state.patient.created_date).toLocaleTimeString()}</small></p>
                    <label>Last login</label>
                    <p className="mt0"><small>{new Date(this.state.patient.last_login_date).toLocaleDateString()} {new Date(this.state.patient.last_login_date).toLocaleTimeString()}</small></p>
                </div>
            :
                <div className="datatable">
                    <DataTable value={this.state.displayPatients} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} header={this.headerPatient} footer={this.footerPatient}
                        tableStyle={{ minWidth: '60rem' }}>
                        <Column field="firstname" header="Firstname" style={{ width: '25%' }} sortable ></Column>
                        <Column field="lastname" header="Lastname" style={{ width: '25%' }} sortable ></Column>
                        <Column field="social_number" header="Social number" style={{ width: '25%' }} sortable ></Column>
                        <Column field="nb_certificates" header="Nb certificates" style={{ width: '15%' }} sortable ></Column>
                        <Column rowEditor headerStyle={{ width: '10%' }} body={this.detailsPatient}></Column>
                    </DataTable>
                </div>
        );
    }
}

export default withTranslation()(Patients);