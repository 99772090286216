import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from "i18next";
import AdminService from '../services/admin_services';

// Images
import LOGO from "../assets/img/vigicard.png";

class HeaderAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lg: i18next.language,
        }
    }

    componentDidMount = async () => {
        if (localStorage.getItem("i18nextLng"))
            this.switchLanguage(localStorage.getItem("i18nextLng"))
    }

    switchLanguage = (lg) => {
        i18next.changeLanguage(lg)
        this.setState({ lg })
        localStorage.setItem("i18nextLng", lg)
    }

    render() {
        return (
            <header>
                <img src={LOGO} alt={'Logo Vigicard'} onClick={() => window.location.href = '/dashboard'} />
                <div className="menu">
                    <a href={"/dashboard"} className="item">
                        Dashboard
                    </a>
                    <div className="item" onClick={() => AdminService.logout()}>
                        Log out
                    </div>
                </div>
            </header>
        );
    }
}

export default withTranslation()(HeaderAdmin);