import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import HeaderAdmin from '../../layout/admin';
import Footer from '../../layout/footer';
import { FaUsers, FaStethoscope, FaShieldVirus, FaUserCog, FaRegUser } from "react-icons/fa";
import AdminService from "../../services/admin_services";
import Patients from './patients';
import Practitioners from './practitioners';
import Allergologists from './allergologists';
import Admins from './admins';
import Subscribers from './subscribers';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            panel: 'patients',
            patients: [],
            practitioners: [],
            allergologists: [],
            admins: [],
            subscribers: [],
            nb_subscribers: '',
            nb_trials: '',
            deactivated: false,
        }
    }

    componentDidMount = async () => {
        this.fetchPatients()
        this.fetchPractitioners()
        this.fetchAllergologists()
        this.fetchAdmins()
        let newsletters = await AdminService.getSubscribers(), subscribers = []
        for(let visitor of newsletters){
            visitor.sub_type = 'Newsletter'
            subscribers.push(visitor)
        }
        this.setState({nb_subscribers: newsletters.length})
        const trials = await AdminService.getTrials()
        for(let visitor of trials){
            visitor.sub_type = 'Trials'
            subscribers.push(visitor)
        }
        this.setState({ subscribers, nb_trials: trials.length })
    }

    fetchPatients = async() => {
        this.setState({ patients: [] })
        const patients = await AdminService.getPatients()
        this.setState({ patients })
    }

    fetchPractitioners = async(deactivated = false) => {
        this.setState({ practitioners: [], deactivated })
        const practitioners = await AdminService.getPractitioners(deactivated)
        this.setState({ practitioners })
    }

    fetchAllergologists = async() => {
        this.setState({ allergologists: [] })
        const allergologists = await AdminService.getAllergologists()
        this.setState({ allergologists })
    }

    fetchAdmins = async(deactivated = false) => {
        this.setState({ admins: [], deactivated })
        const admins = await AdminService.getAdmins(deactivated)
        this.setState({ admins })
    }

    render() {
        return (
            <div className="main">
                <HeaderAdmin />
                <content>
                    <h1 style={{ marginTop: "-40px" }}>Dashboard<br /></h1>
                    <p>Click to switch the displayed panel</p>
                    <div className={this.state.panel === "patients" ? "panel_active" : "panel"} onClick={() => this.setState({ panel: "patients", deactivated: false })}>
                        <h1 style={{ marginBottom: "0" }}><FaUsers /></h1>
                        <h3 style={{ marginTop: "0" }}>Users (patients)</h3>
                        <h2>{this.state.patients.length}</h2>
                    </div>
                    <div className={this.state.panel === "practitioners" ? "panel_active" : "panel"} onClick={() => this.setState({ panel: "practitioners", deactivated: false })}>
                        <h1 style={{ marginBottom: "0" }}><FaStethoscope /></h1>
                        <h3 style={{ marginTop: "0" }}>Practitioners</h3>
                        <h2>{this.state.practitioners.length}</h2>
                    </div>
                    <div className={this.state.panel === "allergologists" ? "panel_active" : "panel"} onClick={() => this.setState({ panel: "allergologists", deactivated: false })}>
                        <h1 style={{ marginBottom: "0" }}><FaShieldVirus /></h1>
                        <h3 style={{ marginTop: "0" }}>Allergologists</h3>
                        <h2>{this.state.allergologists.length}</h2>
                    </div>
                    <div className={this.state.panel === "admins" ? "panel_active" : "panel"} onClick={() => this.setState({ panel: "admins", deactivated: false })}>
                        <h1 style={{ marginBottom: "0" }}><FaUserCog /></h1>
                        <h3 style={{ marginTop: "0" }}>Administrators</h3>
                        <h2>{this.state.admins.length}</h2>
                    </div>
                    <div className={this.state.panel === "subscribers" ? "panel_active" : "panel"} onClick={() => this.setState({ panel: "subscribers", deactivated: false })}>
                        <h1 style={{ marginBottom: "0" }}><FaRegUser /></h1>
                        <h3 style={{ marginTop: "0" }}>Subscribers</h3>
                        <h4>{this.state.nb_trials} <small>trials</small> | {this.state.nb_subscribers} <small>newsletter</small></h4>
                    </div>
                    {this.state.panel === "patients" && this.state.patients.length > 0 && <Patients patients={this.state.patients} fetchPatients={this.fetchPatients}/>}
                    {this.state.panel === "practitioners" && this.state.practitioners.length > 0 && <Practitioners practitioners={this.state.practitioners} fetchPractitioners={this.fetchPractitioners} deactivated={this.state.deactivated}/>}
                    {this.state.panel === "allergologists" && this.state.allergologists.length > 0 && <Allergologists allergologists={this.state.allergologists} fetchAllergologists={this.fetchAllergologists}/>}
                    {this.state.panel === "admins" && this.state.admins.length > 0 && <Admins admins={this.state.admins} fetchAdmins={this.fetchAdmins} deactivated={this.state.deactivated}/>}
                    {this.state.panel === "subscribers" && this.state.subscribers.length > 0 && <Subscribers subscribers={this.state.subscribers}/>}
                </content>
                <Footer />
            </div>
        );
    }
}

export default withTranslation()(Dashboard);