import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from "i18next";
import { FaLinkedin} from 'react-icons/fa';

// Images
import LOGO from "../assets/img/vigicard.png";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lg: i18next.language,
        }
    }

    componentDidMount = async() => {
        if(localStorage.getItem("i18nextLng"))
            this.switchLanguage(localStorage.getItem("i18nextLng"))
    }

    switchLanguage = (lg) => {
        i18next.changeLanguage(lg)
        this.setState({ lg })
        localStorage.setItem("i18nextLng", lg)
    }

    render() {
        return (
            <header>
                <img src={LOGO} alt={'Logo Vigicard'} onClick={() => window.location.href = '/'} />
                <div className="menu">
                    <a href={"/#trial"} className="item">
                        {this.props.t('join_trials')}
                    </a>
                    <a href={"/#contact"} className="item">
                        {this.props.t('contact_us')}
                    </a>
                    <a href={"/stats"} className="item">
                        {this.props.t('stats')}
                    </a>
                    <a href="https://www.linkedin.com/showcase/vigicard" target='_blank' rel="noreferrer" className="item">
                        <big><FaLinkedin/></big>
                    </a>
                    {this.state.lg === "fr" ?
                        <div className="item" onClick={() => this.switchLanguage('en')}>
                            EN
                        </div>
                        :
                        <div className="item" onClick={() => this.switchLanguage('fr')}>
                            FR
                        </div>
                    }
                </div>
            </header>
        );
    }
}

export default withTranslation()(Header);