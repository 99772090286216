import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/Home";
import Stats from "./pages/Stats";
import Authentication from './pages/Authentication';
import Dashboard from './pages/Dashboard';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/stats" element={<Stats />} />
              <Route path="/auth" element={<Authentication />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </BrowserRouter>
        );
    }
}

export default withTranslation()(App)