export const NETWORK = "ghostnet"
export const TEZOS_NODE = "https://ghostnet.smartpy.io"
export const CHAIN_ID = "NetXnHfVqm9iesp"
export const VIGI_CONTRACT = "KT1Kx2MbJx3wX2MwH1cMiqxSq17S8yESojMZ"
export const BIGMAP_CERTIFICATES = 311083
export const BIGMAP_MINTERS = 311079
export const BIGMAP_PRACTITIONERS = 311081
export const BIGMAP_LEDGER = 311077
export const BIGMAP_PROPOSALS = 311082
export const SITE_KEY = "ac24ef84-1546-4174-9094-20e19ed6c448"
