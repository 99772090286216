import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Header from '../../layout/header';
import Footer from '../../layout/footer';
import AdminService from '../../services/admin_services';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { SITE_KEY } from '../../constants';

class Authentication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: null,
            email: null,
            password: null,
            success: false,
            error: null,
            info: null,
        }
    }

    handleEmail = (e) => {
        this.setState({ email: e.target.value });
    }

    handlePswd = (e) => {
        this.setState({ password: e.target.value });
    }

    onVerifyCaptcha = (token) => {
        this.setState({ token })
    }

    signIn = async () => {
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(this.state.email) || !this.state.password) {
            this.setState({ error: 'Please fill credentials with correct format', info: null, success: false })
        } else {
            this.setState({ info: 'in_progress', error: null, success: false, loading: true });
            try {
                const resp = await AdminService.login(this.state.email, this.state.password, this.state.token)
                if (resp.status)
                    window.location.href = '/dashboard'
                this.setState({ success: resp.status, info: null, error: !resp.status && resp.message, loading: false })
            } catch (e) {
                console.log(e)
                this.setState({ success: false, info: null, error: "Failed to sign in. Incorrect credentials.", loading: false })
            }
        }
    }



    render() {
        return (
            <div className="main">
                <Header />
                <content>
                    <h1 style={{ marginTop: "-40px" }}>Admin access<br /><small>Set credentials to authentify</small></h1>
                    <div className="stat">
                        <h3 style={{ marginTop: "0", marginBottom: "0" }}>Email address</h3>
                        <input type="email" placeholder="admin@mail.com" className="credential" onChange={this.handleEmail} />
                        <h3 style={{ marginTop: "0", marginBottom: "0" }}>Secure password</h3>
                        <input type="password" placeholder="password" className="credential" onChange={this.handlePswd} />
                        <HCaptcha
                            sitekey={SITE_KEY}
                            onVerify={this.onVerifyCaptcha}
                        />
                        {this.state.error && <p className="text-error">{this.props.t(this.state.error)}</p>}
                        {this.state.info ?
                            <p>Processing...</p>
                            :
                            !this.state.success &&
                            <div className="call2action" onClick={this.signIn}>Sign In</div>
                        }
                    </div>
                    <Footer />
                </content>
            </div>
        );
    }
}

export default withTranslation()(Authentication);