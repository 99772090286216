import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Images
import LOGO from "../assets/img/vigicard.png";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <footer className={this.props.mv && "mv0"}>
                <div className="block">
                    <img src={LOGO} alt={'Logo Vigicard'} className="logo" />
                    <p><Link to={"https://codinsight.com/privacy"} rel="noopener noreferrer" target="_blank">{this.props.t('privacy')}</Link></p>
                </div>
                <div className="block">
                    <p>
                        <strong>{this.props.t('web_editor')}</strong>
                        <br />
                        {this.props.t('editor_site')}
                        <br />
                        {this.props.t('immatriculation')}
                        <br />
                        Email : <Link to={"mailTo:contact@codinsight.com"}>contact@codinsight.com</Link>
                        <br />
                        {this.props.t('publication')} : M. Fabien Bucamp.
                    </p>
                </div>
                <div className="block">
                    <p>
                        <strong>{this.props.t('hosting')}</strong>
                        <br />
                        {this.props.t('vigi_hosting')}
                        <br />
                        PO Box 81226, Seattle, WA 981808-1226 – USA.
                        <br />
                        <Link to={"https://aws.amazon.com/fr/compliance/eu-data-protection/"} rel="noopener noreferrer" target="_blank">{this.props.t('aws_compliance')}</Link>
                    </p>
                </div>
            </footer>
        );
    }
}

export default withTranslation()(Footer);