import api from './api';

class SubscriptionService {
      async subscribe(email_address, hcaptcha_passcode) {
        return await api
          .post('/visitor/subscribe/clinical_trial', {
            email_address,
            hcaptcha_passcode,
          })
          .then(async (response) => {
            return response.data;
          });
      }
}

export default new SubscriptionService();
