import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FaPenAlt, FaCheckCircle, FaPlus, FaHourglass, FaTimesCircle, FaArrowLeft, FaLock } from 'react-icons/fa';
import AdminService from "../../services/admin_services";

class Admins extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayAdmins: this.props.admins,
            processing: null,
            deactivated: false,
            creation: false,
            email_address: null,
            username: null,
            password: null,
            firstname: null,
            lastname: null,
            admin: null,
            pswd: false,
        }
    }

    headerAdmin = () => {
        return (
            <React.Fragment>
                <input className="search" placeholder="Search" style={{ display: "inline-block" }} onChange={this.searchAdmin} />
                <button className="details" style={{ display: "inline-block", margin: "10px" }} onClick={() => this.setState({ creation: true })}><FaPlus /></button>
                {!this.props.deactivated ?
                    <button className="details" style={{ display: "inline-block", margin: "10px" }} onClick={() => { this.props.fetchAdmins(true) }}><FaTimesCircle /></button>
                    :
                    <button className="details" style={{ display: "inline-block", margin: "10px" }} onClick={() => { this.props.fetchAdmins(false) }}><FaCheckCircle /></button>
                }
            </React.Fragment>
        )
    }

    footerAdmin = () => {
        return (
            <p>{this.state.displayAdmins.length} profiles referenced</p>
        )
    }

    searchAdmin = (e) => {
        let displayAdmins = [], filter = e.target.value.toLowerCase();
        for (let admin of this.props.admins) {
            if (admin.firstname.toLowerCase().includes(filter) || admin.lastname.toLowerCase().includes(filter) || admin.username.toLowerCase().includes(filter) || admin.email.toLowerCase().includes(filter))
                displayAdmins.push(admin)
        }
        this.setState({ displayAdmins })
    }

    updt = (rowData, column) => {
        return (
            <button className="details" onClick={() => this.setState({
                admin: rowData,
                email_address: rowData.email,
                username: rowData.username,
                firstname: rowData.firstname,
                lastname: rowData.lastname,
            })}><FaPenAlt /></button>
        )
    }

    updtPswd = (rowData, column) => {
        return (
            <button className="details" onClick={() => this.setState({pswd: true, admin: rowData})}><FaLock /></button>
        )
    }

    updateAdmin = (rowData, column) => {
        return (
            this.state.processing === rowData.admin_uuid ?
                <button className="details"><FaHourglass /></button>
                : rowData.deactivated ?
                    <button className="reactivate" onClick={() => this.reactivate(rowData)}>Reactivate</button>
                    :
                    <button className="deactivate" onClick={() => this.deactivate(rowData)}>Deactivate</button>
        )
    }

    reactivate = async (rowData) => {
        this.setState({ processing: rowData.admin_uuid })
        const resp = await AdminService.reactivateAdmin(rowData.admin_uuid)
        console.log(resp)
        this.props.fetchAdmins(!this.props.deactivated)
    }

    deactivate = async (rowData) => {
        this.setState({ processing: rowData.admin_uuid })
        const resp = await AdminService.deactivateAdmin(rowData.admin_uuid)
        console.log(resp)
        this.props.fetchAdmins(!this.props.deactivated)
    }

    createAccount = async () => {
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(this.state.email_address) || !this.state.password || this.state.password.length < 8
            || !this.state.username || !this.state.firstname || !this.state.lastname) {
            this.setState({ error: 'Please fill all required info with correct format', info: null, success: false })
        } else {
            this.setState({ info: 'in_progress', error: null, success: false, loading: true });
            try {
                const resp = await AdminService.createAdmin({
                    email_address: this.state.email_address,
                    username: this.state.username,
                    password: this.state.password,
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                })
                if (resp.status)
                    this.props.fetchAdmins(false)
                this.setState({ success: resp.status, info: null, error: !resp.status && resp.message, loading: false })
            } catch (e) {
                console.log(e)
                this.setState({ success: false, info: null, error: "Failed to sign in. Incorrect credentials.", loading: false })
            }
        }
    }

    updateAccount = async () => {
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(this.state.email_address) || !this.state.username || !this.state.firstname || !this.state.lastname) {
            this.setState({ error: 'Please fill all required info with correct format', info: null, success: false })
        } else {
            this.setState({ info: 'in_progress', error: null, success: false, loading: true });
            try {
                const resp = await AdminService.updateAdmin(this.state.admin.admin_uuid, {
                    email_address: this.state.email_address,
                    username: this.state.username,
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                })
                if (resp.status)
                    this.props.fetchAdmins(false)
                this.setState({ success: resp.status, info: null, error: !resp.status && resp.message, loading: false })
            } catch (e) {
                console.log(e)
                this.setState({ success: false, info: null, error: "Failed to update.", loading: false })
            }
        }
    }

    updatePswd = async () => {
        if (!this.state.password || this.state.password.length < 8) {
            this.setState({ error: 'Please fill all required info with correct format', info: null, success: false })
        } else {
            this.setState({ info: 'in_progress', error: null, success: false, loading: true });
            try {
                const resp = await AdminService.updatePassword(this.state.admin.admin_uuid, this.state.password)
                if (resp.status)
                    this.props.fetchAdmins(false)
                this.setState({ success: resp.status, info: null, error: !resp.status && resp.message, loading: false })
            } catch (e) {
                console.log(e)
                this.setState({ success: false, info: null, error: "Failed to update.", loading: false })
            }
        }
    }

    render() {
        return (
            this.state.pswd ?
                <div className="profile">
                    <button className="details" onClick={() => this.setState({ pswd: false, admin: null })}><FaArrowLeft /></button>
                    <h3>Update admin password</h3>
                    <label>Password</label>
                    <input type="password" placeholder="Min. 8 characters" className="credential" onChange={(e) => this.setState({ password: e.target.value })}/>
                    {this.state.error && <p className="text-error">{this.props.t(this.state.error)}</p>}
                    {this.state.info ?
                        <p>Processing...</p>
                        :
                        !this.state.success &&
                        <div className="call2action" onClick={this.updatePswd}>Update password</div>
                    }
                </div>
                :
                this.state.admin ?
                    <div className="profile">
                        <button className="details" onClick={() => this.setState({ admin: null })}><FaArrowLeft /></button>
                        <h3>Update admin account</h3>
                        <label>Username</label>
                        <input type="text" placeholder="john.doe" className="credential" onChange={(e) => this.setState({ username: e.target.value })} value={this.state.username}/>
                        <label>Email</label>
                        <input type="email" placeholder="john.doe@mail.com" className="credential" onChange={(e) => this.setState({ email_address: e.target.value })} value={this.state.email_address}/>
                        <label>Firstname</label>
                        <input type="text" placeholder="John" className="credential" onChange={(e) => this.setState({ firstname: e.target.value })} value={this.state.firstname}/>
                        <label>Lastname</label>
                        <input type="text" placeholder="Doe" className="credential" onChange={(e) => this.setState({ lastname: e.target.value })} value={this.state.lastname}/>
                        {this.state.error && <p className="text-error">{this.props.t(this.state.error)}</p>}
                        {this.state.info ?
                            <p>Processing...</p>
                            :
                            !this.state.success &&
                            <div className="call2action" onClick={this.updateAccount}>Update account</div>
                        }
                    </div>
                    :
                this.state.creation ?
                    <div className="profile">
                        <button className="details" onClick={() => this.setState({ creation: false })}><FaArrowLeft /></button>
                        <h3>New admin account</h3>
                        <label>Username</label>
                        <input type="text" placeholder="john.doe" className="credential" onChange={(e) => this.setState({ username: e.target.value })} />
                        <label>Email</label>
                        <input type="email" placeholder="john.doe@mail.com" className="credential" onChange={(e) => this.setState({ email_address: e.target.value })} />
                        <label>Firstname</label>
                        <input type="text" placeholder="John" className="credential" onChange={(e) => this.setState({ firstname: e.target.value })} />
                        <label>Lastname</label>
                        <input type="text" placeholder="Doe" className="credential" onChange={(e) => this.setState({ lastname: e.target.value })} />
                        <label>Password</label>
                        <input type="password" placeholder="Min 8 characters" className="credential" onChange={(e) => this.setState({ password: e.target.value })} />
                        {this.state.error && <p className="text-error">{this.props.t(this.state.error)}</p>}
                        {this.state.info ?
                            <p>Processing...</p>
                            :
                            !this.state.success &&
                            <div className="call2action" onClick={this.createAccount}>Create account</div>
                        }
                    </div>
                    :
                    <div className="datatable">
                        <DataTable value={this.state.displayAdmins} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} header={this.headerAdmin} footer={this.footerAdmin}
                            tableStyle={{ minWidth: '60rem' }}>
                            <Column field="firstname" header="Firstname" style={{ width: '15%' }} sortable ></Column>
                            <Column field="lastname" header="Lastname" style={{ width: '15%' }} sortable ></Column>
                            <Column field="username" header="Username" style={{ width: '15%' }} sortable ></Column>
                            <Column field="email" header="Email" style={{ width: '15%' }} sortable ></Column>
                            <Column rowEditor headerStyle={{ width: '20%' }} body={this.updateAdmin}></Column>
                            <Column rowEditor headerStyle={{ width: '10%' }} body={this.updt}></Column>
                            <Column rowEditor headerStyle={{ width: '10%' }} body={this.updtPswd}></Column>
                        </DataTable>
                    </div>
        );
    }
}

export default withTranslation()(Admins);