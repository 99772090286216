import api from './api';
import TokenService from './token_services';

class AdminService {
  async login(login, password, hcaptcha_passcode) {
    return await api
      .post('/admin/login', {
        login,
        password,
        hcaptcha_passcode,
      })
      .then(async (response) => {
        if (response.data.jwt_token) {
          await TokenService.setUser(response.data);
        }
        return response.data;
      });
  }

  async getPatients(deactivated = false) {
    return await api
      .get(`/patient?deactivated=${deactivated}`)
      .then(async (response) => {
        return response.data.patients;
      });
  }

  async getPatient(patient_uuid) {
    return await api
      .get(`/patient/${patient_uuid}`)
      .then(async (response) => {
        return response.data.patient;
      });
  }

  async getPractitioners(deactivated = false) {
    return await api
      .get(`/practitioner?deactivated=${deactivated}&specialist=false`)
      .then(async (response) => {
        return response.data.practitioners;
      });
  }

  async getPractitioner(practitioner_uuid) {
    return await api
      .get(`/practitioner/${practitioner_uuid}`)
      .then(async (response) => {
        return response.data.account;
      });
  }

  async getAllergologists(deactivated = false) {
    return await api
      .get(`/practitioner?deactivated=${deactivated}&specialist=true`)
      .then(async (response) => {
        return response.data.practitioners;
      });
  }

  async getAdmins(deactivated = false) {
    return await api
      .get(`/admin/account?deactivated=${deactivated}`)
      .then(async (response) => {
        return response.data.admin;
      });
  }

  async getSubscribers() {
    return await api
      .get(`/visitor/newsletter`)
      .then(async (response) => {
        return response.data.subscribers;
      });
  }

  async getTrials() {
    return await api
      .get(`/visitor/clinical_trial`)
      .then(async (response) => {
        return response.data.subscribers;
      });
  }

  async deactivatePractitioner(practitioner_uuid) {
    return await api
      .post('/practitioner/deactivate', {
        practitioner_uuid,
      })
      .then(async (response) => {
        return response.data;
      });
  }

  async reactivatePractitioner(practitioner_uuid) {
    return await api
      .post('/practitioner/reactivate', {
        practitioner_uuid,
      })
      .then(async (response) => {
        return response.data;
      });
  }

  async setSpecialist(practitioner_uuid) {
    return await api
      .post('/practitioner/specialist', {
        practitioner_uuid,
        specialist: true
      })
      .then(async (response) => {
        return response.data;
      });
  }

  async removeSpecialist(practitioner_uuid) {
    return await api
      .post('/practitioner/specialist', {
        practitioner_uuid,
        specialist: false
      })
      .then(async (response) => {
        return response.data;
      });
  }

  async deactivateAdmin(admin_uuid) {
    return await api
      .post('/admin/account/deactivate', {
        admin_uuid,
      })
      .then(async (response) => {
        return response.data;
      });
  }

  async reactivateAdmin(admin_uuid) {
    return await api
      .post('/admin/account/reactivate', {
        admin_uuid,
      })
      .then(async (response) => {
        return response.data;
      });
  }

  async createAdmin(account) {
    return await api
      .post('/admin/account',account)
      .then(async (response) => {
        return response.data;
      });
  }

  async updateAdmin(admin_uuid, account) {
    return await api
      .post(`/admin/account/${admin_uuid}`, account)
      .then(async (response) => {
        return response.data;
      });
  }

  async updatePassword(admin_uuid, password) {
    return await api
      .post(`/admin/account/${admin_uuid}`, {password})
      .then(async (response) => {
        return response.data;
      });
  }

  async logout() {
    await TokenService.removeUser();
  }

  async getCurrentUser() {
    return await TokenService.getUser();
  }
}

export default new AdminService();