import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FaArrowLeft, FaEye } from "react-icons/fa";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AdminService from "../../services/admin_services";

class Subscribers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displaySubscribers: this.props.subscribers,
            subscriber: null,
        }
    }

    headerSubscriber = () => {
        return (
            <input className="search" placeholder="Search" onChange={this.searchSubscriber} />
        )
    }

    footerSubscriber = () => {
        return (
            <p>{this.state.displaySubscribers.length} profiles referenced</p>
        )
    }

    dateSubscriber = (rowData, column) => {
        return (
            new Date(rowData.created_date).toLocaleDateString() + " " + new Date(rowData.created_date).toLocaleTimeString()
        )
    }

    searchSubscriber = (e) => {
        let displaySubscribers = [], filter = e.target.value.toLowerCase();
        for (let subscriber of this.props.subscribers) {
            if (subscriber.firstname.toLowerCase().includes(filter) || subscriber.lastname.toLowerCase().includes(filter) || subscriber.social_number.toLowerCase().includes(filter))
                displaySubscribers.push(subscriber)
        }
        this.setState({ displaySubscribers })
    }

    render() {
        return (
                <div className="datatable">
                    <DataTable value={this.state.displaySubscribers} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} header={this.headerSubscriber} footer={this.footerSubscriber}
                        tableStyle={{ minWidth: '60rem' }}>
                        <Column field="email" header="Email" style={{ width: '40%' }} sortable ></Column>
                        <Column header="Subscription date" style={{ width: '40%' }} body={this.dateSubscriber} sortable></Column>
                        <Column field="sub_type" header="Subscription" style={{ width: '20%' }} sortable ></Column>
                    </DataTable>
                </div>
        );
    }
}

export default withTranslation()(Subscribers);