import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FaArrowLeft, FaCheckCircle, FaEye, FaHourglass, FaTimesCircle } from 'react-icons/fa';
import AdminService from "../../services/admin_services";

class Practitioners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayPractitioners: this.props.practitioners,
            practitioner: null,
            processing: null,
        }
    }

    headerPractitioner = () => {
        return (
            <React.Fragment>
                <input className="search" placeholder="Search" onChange={this.searchPractitioner} style={{ display: "inline-block" }} />
                {!this.props.deactivated ?
                    <button className="details" style={{ display: "inline-block", margin: "10px" }} onClick={() => { this.props.fetchPractitioners(true) }}><FaTimesCircle /></button>
                    :
                    <button className="details" style={{ display: "inline-block", margin: "10px" }} onClick={() => { this.props.fetchPractitioners(false) }}><FaCheckCircle /></button>
                }
            </React.Fragment>
        )
    }

    footerPractitioner = () => {
        return (
            <p>{this.state.displayPractitioners.length} profiles referenced</p>
        )
    }

    searchPractitioner = (e) => {
        let displayPractitioners = [], filter = e.target.value.toLowerCase();
        for (let practitioner of this.props.practitioners) {
            if (practitioner.firstname.toLowerCase().includes(filter) || practitioner.lastname.toLowerCase().includes(filter) || practitioner.professional_number.toLowerCase().includes(filter))
                displayPractitioners.push(practitioner)
        }
        this.setState({ displayPractitioners })
    }

    updatePractitioner = (rowData, column) => {
        return (
            this.state.processing === rowData.practitioner_uuid ?
                <button className="details"><FaHourglass /></button>
                : rowData.deactivated ?
                    <button className="reactivate" onClick={() => this.reactivate(rowData)}>Reactivate</button>
                    :
                    <button className="deactivate" onClick={() => this.deactivate(rowData)}>Deactivate</button>
        )
    }

    updateSpecialist = (rowData, column) => {
        return (
            this.state.processing === rowData.practitioner_uuid ?
                <button className="details"><FaHourglass /></button>
                : !rowData.specialist ?
                    <button className="reactivate" onClick={() => this.addSpe(rowData)}>Set as specialist</button>
                    :
                    <button className="deactivate" onClick={() => this.rmSpe(rowData)}>Remove from specialist</button>
        )
    }

    reactivate = async (rowData) => {
        this.setState({ processing: rowData.practitioner_uuid })
        const resp = await AdminService.reactivatePractitioner(rowData.practitioner_uuid)
        console.log(resp)
        this.props.fetchPractitioners(!this.props.deactivated)
    }

    deactivate = async (rowData) => {
        this.setState({ processing: rowData.practitioner_uuid })
        const resp = await AdminService.deactivatePractitioner(rowData.practitioner_uuid)
        console.log(resp)
        this.props.fetchPractitioners(!this.props.deactivated)
    }

    addSpe = async (rowData) => {
        this.setState({ processing: rowData.practitioner_uuid })
        await AdminService.setSpecialist(rowData.practitioner_uuid)
        window.location.reload()
    }

    rmSpe = async (rowData) => {
        this.setState({ processing: rowData.practitioner_uuid })
        await AdminService.removeSpecialist(rowData.practitioner_uuid)
        window.location.reload()
    }

    detailsPractitioner = (rowData, column) => {
        return (
            <button className="details" onClick={() => this.fetchPractitioner(rowData)}><FaEye /></button>
        )
    }

    fetchPractitioner = async (rowData) => {
        const practitioner = await AdminService.getPractitioner(rowData.practitioner_uuid)
        this.setState({ practitioner })
    }

    render() {
        return (
            this.state.practitioner ?
                <div className="profile">
                    <button className="details" onClick={() => this.setState({ practitioner: null })}><FaArrowLeft /></button>
                    <div className="selfie" style={{ backgroundImage: `url('${this.state.practitioner.selfie ? this.state.practitioner.selfie : `https://api.dicebear.com/5.x/big-smile/jpg?seed=${this.state.practitioner.firstname}-${this.state.practitioner.lastname}`}')` }}></div>
                    <h3>Dr. {this.state.practitioner.firstname} {this.state.practitioner.lastname}</h3>
                    <label>Professional number</label>
                    <h4 className="mt0"><strong>{this.state.practitioner.professional_number}</strong></h4>
                    <label>Specialty</label>
                    <h4 className="mt0"><strong>{this.state.practitioner.speciality ? this.state.practitioner.speciality : 'Unreferenced'}</strong></h4>
                    <label>Email</label>
                    <h4 className="mt0">{this.state.practitioner.email_address}</h4>
                    <label>Chain address</label>
                    <p className="mt0"><small>{this.state.practitioner.pk_hash}</small></p>
                    <label>Creation date</label>
                    <p className="mt0"><small>{new Date(this.state.practitioner.created_date).toLocaleDateString()} {new Date(this.state.practitioner.created_date).toLocaleTimeString()}</small></p>
                    <label>Last login</label>
                    <p className="mt0"><small>{new Date(this.state.practitioner.last_login_date).toLocaleDateString()} {new Date(this.state.practitioner.last_login_date).toLocaleTimeString()}</small></p>
                </div>
                :
                <div className="datatable">
                    <DataTable value={this.state.displayPractitioners} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} header={this.headerPractitioner} footer={this.footerPractitioner}
                        tableStyle={{ minWidth: '60rem' }}>
                        <Column field="firstname" header="Firstname" style={{ width: '15%' }} sortable ></Column>
                        <Column field="lastname" header="Lastname" style={{ width: '15%' }} sortable ></Column>
                        <Column field="professional_number" header="Pro. number" style={{ width: '15%' }} sortable ></Column>
                        <Column field="speciality" header="Specialty" style={{ width: '15%' }} sortable ></Column>
                        <Column field="nb_proposals" header="Nb proposals" style={{ width: '5%' }} sortable ></Column>
                        <Column rowEditor headerStyle={{ width: '10%' }} body={this.updatePractitioner}></Column>
                        <Column rowEditor headerStyle={{ width: '20%' }} body={this.updateSpecialist}></Column>
                        <Column rowEditor headerStyle={{ width: '5%' }} body={this.detailsPractitioner}></Column>
                    </DataTable>
                </div>
        );
    }
}

export default withTranslation()(Practitioners);