import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FaArrowLeft, FaEye, FaHourglass, FaTimesCircle, FaCheckCircle } from 'react-icons/fa';
import AdminService from "../../services/admin_services";

class Allergologists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayAllergologists: this.props.allergologists,
            allergologist: null,
            processing: null,
        }
    }

    headerAllergologist = () => {
        return (
            <input className="search" placeholder="Search" onChange={this.searchAllergologist}/>
        )
    }

    footerAllergologist = () => {
        return (
            <p>{this.state.displayAllergologists.length} profiles referenced</p>
        )
    }

    searchAllergologist = (e) => {
        let displayAllergologists = [], filter = e.target.value.toLowerCase();
        for (let allergologist of this.props.allergologists) {
            if (allergologist.firstname.toLowerCase().includes(filter) || allergologist.lastname.toLowerCase().includes(filter) || allergologist.professional_number.toLowerCase().includes(filter))
                displayAllergologists.push(allergologist)
        }
        this.setState({ displayAllergologists })
    }

    updateAllergologist = (rowData, column) => {
        return (
            this.state.processing === rowData.practitioner_uuid ?
                <button className="details"><FaHourglass /></button>
                : rowData.deactivated ?
                    <button className="reactivate" onClick={() => this.reactivate(rowData)}>Reactivate</button>
                    :
                    <button className="deactivate" onClick={() => this.deactivate(rowData)}>Deactivate</button>
        )
    }

    reactivate = async (rowData) => {
        this.setState({ processing: rowData.practitioner_uuid })
        const resp = await AdminService.reactivatePractitioner(rowData.practitioner_uuid)
        window.location.reload()
    }

    deactivate = async (rowData) => {
        this.setState({ processing: rowData.practitioner_uuid })
        const resp = await AdminService.deactivatePractitioner(rowData.practitioner_uuid)
        window.location.reload()
    }

    detailsAllergologist = (rowData, column) => {
        return (
            <button className="details" onClick={() => this.fetchAllergologist(rowData)}><FaEye /></button>
        )
    }

    fetchAllergologist = async (rowData) => {
        const allergologist = await AdminService.getPractitioner(rowData.practitioner_uuid)
        this.setState({ allergologist })
    }

    render() {
        return (
            this.state.allergologist ?
                <div className="profile">
                    <button className="details" onClick={() => this.setState({ allergologist: null })}><FaArrowLeft /></button>
                    <div className="selfie" style={{ backgroundImage: `url('${this.state.allergologist.selfie ? this.state.allergologist.selfie : `https://api.dicebear.com/5.x/big-smile/jpg?seed=${this.state.allergologist.firstname}-${this.state.allergologist.lastname}`}')` }}></div>
                    <h3>Dr. {this.state.allergologist.firstname} {this.state.allergologist.lastname}</h3>
                    <label>Professional number</label>
                    <h4 className="mt0"><strong>{this.state.allergologist.professional_number}</strong></h4>
                    <label>Specialty</label>
                    <h4 className="mt0"><strong>{'Allergologist'}</strong></h4>
                    <label>Email</label>
                    <h4 className="mt0">{this.state.allergologist.email_address}</h4>
                    <label>Chain address</label>
                    <p className="mt0"><small>{this.state.allergologist.pk_hash}</small></p>
                    <label>Creation date</label>
                    <p className="mt0"><small>{new Date(this.state.allergologist.created_date).toLocaleDateString()} {new Date(this.state.allergologist.created_date).toLocaleTimeString()}</small></p>
                    <label>Last login</label>
                    <p className="mt0"><small>{new Date(this.state.allergologist.last_login_date).toLocaleDateString()} {new Date(this.state.allergologist.last_login_date).toLocaleTimeString()}</small></p>
                </div>
                :
                <div className="datatable">
                    <DataTable value={this.state.displayAllergologists} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} header={this.headerAllergologist} footer={this.footerAllergologist}
                        tableStyle={{ minWidth: '60rem' }}>
                        <Column field="firstname" header="Firstname" style={{ width: '20%' }} sortable ></Column>
                        <Column field="lastname" header="Lastname" style={{ width: '20%' }} sortable ></Column>
                        <Column field="professional_number" header="Social number" style={{ width: '25%' }} sortable ></Column>
                        <Column field="nb_certificates" header="Nb certificates" style={{ width: '15%' }} sortable ></Column>
                        <Column rowEditor headerStyle={{ width: '10%' }} body={this.updateAllergologist}></Column>
                        <Column rowEditor headerStyle={{ width: '10%' }} body={this.detailsAllergologist}></Column>
                    </DataTable>
                </div>
        );
    }
}

export default withTranslation()(Allergologists);