import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Header from '../../layout/header';
import Footer from '../../layout/footer';
import { FaUsers, FaStethoscope, FaClock, FaCertificate, FaNotesMedical, FaHourglass, FaCode, FaShieldVirus, FaStamp } from "react-icons/fa";
import { LineChart, Line, XAxis, Tooltip, Legend } from 'recharts';
import { BIGMAP_CERTIFICATES, BIGMAP_LEDGER, BIGMAP_MINTERS, BIGMAP_PRACTITIONERS, BIGMAP_PROPOSALS, NETWORK, VIGI_CONTRACT } from '../../constants';
import axios from 'axios';
import { Link } from 'react-router-dom';

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

class Stats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            certificates: [],
            allergologists: [],
            practitioners: [],
            patients: [],
            proposals: [],
            ops: [],
            totalCertificates: '-',
            totalPatients: '-',
            totalPractitioners: '-',
            totalAllergologists: '-',
            totalProposals: '-',
            totalOps: '-',
        }
    }

    componentDidMount = async () => {
        let months = [], index = 0, certificates = {}, patients = {}, addresses = [], allergologists = {}, practitioners = {}, proposals = {}, ops = {},
            totalCertificates = 0, totalPatients = 0, totalAllergologists = 0, totalPractitioners = 0, totalProposals = 0, totalOps = 0;
        while (index < 13) { let date = new Date(); months = [(new Date(date.setMonth(date.getMonth() - index + 1)).toISOString()), ...months]; index++; }

        // LOAD CERTIFICATES
        let resp = await axios.get(`https://api.${NETWORK}.tzkt.io/v1/bigmaps/updates?contract=${VIGI_CONTRACT}&bigmap=${BIGMAP_CERTIFICATES}&limit=10000`)
        for (let entry of resp.data) {
            if (entry.action === "add_key") {
                totalCertificates++;
                certificates[parseInt(new Date(entry.timestamp).getMonth())] = totalCertificates
                if (!certificates[parseInt(new Date(entry.timestamp).getMonth() - 1)])
                    certificates[parseInt(new Date(entry.timestamp).getMonth() - 1)] = 0
            }
        }
        this.setState({ totalCertificates, certificates: Object.keys(certificates).sort().map((key) => ({ month: monthNames[key], certificates: certificates[key] })) })

        // LOAD PATIENTS
        resp = await axios.get(`https://api.${NETWORK}.tzkt.io/v1/bigmaps/updates?contract=${VIGI_CONTRACT}&bigmap=${BIGMAP_LEDGER}&limit=10000`)
        for (let entry of resp.data) {
            if (entry.action === "add_key" && entry.content && entry.content.key) {
                if (!addresses.includes(entry.content.key.address)) {
                    addresses.push(entry.content.key.address)
                    totalPatients++;
                    patients[parseInt(new Date(entry.timestamp).getMonth())] = totalPatients
                    if (!patients[parseInt(new Date(entry.timestamp).getMonth() - 1)])
                        patients[parseInt(new Date(entry.timestamp).getMonth() - 1)] = 0
                }
            }
        }
        this.setState({ totalPatients, patients: Object.keys(patients).sort().map((key) => ({ month: monthNames[key], patients: patients[key] })) })

        // LOAD ALLERGOLOGISTS
        resp = await axios.get(`https://api.${NETWORK}.tzkt.io/v1/bigmaps/updates?contract=${VIGI_CONTRACT}&bigmap=${BIGMAP_MINTERS}`)
        for (let entry of resp.data) {
            if (entry.action === "add_key") {
                totalAllergologists++;
                allergologists[parseInt(new Date(entry.timestamp).getMonth())] = totalAllergologists
                if (!allergologists[parseInt(new Date(entry.timestamp).getMonth() - 1)])
                    allergologists[parseInt(new Date(entry.timestamp).getMonth() - 1)] = 0
            }
        }
        this.setState({ totalAllergologists, allergologists: Object.keys(allergologists).sort().map((key) => ({ month: monthNames[key], allergologists: allergologists[key] })) })

        // LOAD PRACTITIONERS
        resp = await axios.get(`https://api.${NETWORK}.tzkt.io/v1/bigmaps/updates?contract=${VIGI_CONTRACT}&bigmap=${BIGMAP_PRACTITIONERS}`)
        for (let entry of resp.data) {
            if (entry.action === "add_key") {
                totalPractitioners++;
                practitioners[parseInt(new Date(entry.timestamp).getMonth())] = totalPractitioners
                if (!practitioners[parseInt(new Date(entry.timestamp).getMonth() - 1)])
                    practitioners[parseInt(new Date(entry.timestamp).getMonth() - 1)] = 0
            }
        }
        this.setState({ totalPractitioners, practitioners: Object.keys(practitioners).sort().map((key) => ({ month: monthNames[key], practitioners: practitioners[key] })) })

        // LOAD PROPOSALS
        resp = await axios.get(`https://api.${NETWORK}.tzkt.io/v1/bigmaps/updates?contract=${VIGI_CONTRACT}&bigmap=${BIGMAP_PROPOSALS}`)
        for (let entry of resp.data) {
            if (entry.action === "add_key") {
                totalProposals++;
                proposals[parseInt(new Date(entry.timestamp).getMonth())] = totalProposals
                if (!proposals[parseInt(new Date(entry.timestamp).getMonth() - 1)])
                    proposals[parseInt(new Date(entry.timestamp).getMonth() - 1)] = 0
            }
        }
        this.setState({ totalProposals, proposals: Object.keys(proposals).sort().map((key) => ({ month: monthNames[key], proposals: proposals[key] })) })

        // LOAD OPERATIONS
        resp = await axios.get(`https://api.${NETWORK}.tzkt.io/v1/operations/transactions?&target=${VIGI_CONTRACT}`)
        for (let entry of resp.data) {
            totalOps++;
            ops[parseInt(new Date(entry.timestamp).getMonth())] = totalOps
            if (!ops[parseInt(new Date(entry.timestamp).getMonth() - 1)])
                ops[parseInt(new Date(entry.timestamp).getMonth() - 1)] = 0
        }
        this.setState({ totalOps, ops: Object.keys(ops).sort().map((key) => ({ month: monthNames[key], ops: ops[key] })) })
    }

    render() {
        return (
            <div className="main">
                <Header />
                <content>
                    <h1 style={{ marginTop: "-40px" }}>{this.props.t('stats')}<br /><small>{this.props.t('stats_info')}</small></h1>
                    <div className="stat">
                        <h1 style={{ marginBottom: "0" }}><FaClock /></h1>
                        <h3 style={{ marginTop: "0" }}>{this.props.t('trial_start')}</h3>
                        <h2>{this.props.t('end_2023')}</h2>
                    </div>
                    <div className="stat">
                        <h1 style={{ marginBottom: "0" }}><FaHourglass /></h1>
                        <h3 style={{ marginTop: "0" }}>{this.props.t('trial_duration')}</h3>
                        <h2>8 {this.props.t('months')}</h2>
                    </div>
                    <div className="stat">
                        <h1 style={{ marginBottom: "0" }}><FaUsers /></h1>
                        <h3 style={{ marginTop: "0" }}>{this.props.t('trial_nb_users')}</h3>
                        <h2>400</h2>
                    </div>
                    <div className="stat">
                        <h1 style={{ marginBottom: "0" }}><FaStethoscope /></h1>
                        <h3 style={{ marginTop: "0" }}>{this.props.t('trial_nb_practitioners')}</h3>
                        <h2>200</h2>
                    </div>
                    <br />
                    <div className="chain">
                        <h1 style={{ marginBottom: "0" }}><FaCertificate /></h1>
                        <h3 style={{ marginTop: "0" }}>{this.props.t('nb_certificates')}</h3>
                        <h2>{this.state.totalCertificates}</h2>
                        <LineChart width={480} height={280} data={this.state.certificates}>
                            <Tooltip />
                            <XAxis dataKey="month" />
                            <Legend />
                            <Line type="monotone" dataKey="certificates" stroke="#00CC00" strokeWidth={2} />
                        </LineChart>
                    </div>
                    <div className="chain">
                        <h1 style={{ marginBottom: "0" }}><FaUsers /></h1>
                        <h3 style={{ marginTop: "0" }}>{this.props.t('registered_patients')}</h3>
                        <h2>{this.state.totalPatients}</h2>
                        <LineChart width={480} height={280} data={this.state.patients}>
                            <Tooltip />
                            <XAxis dataKey="month" />
                            <Legend />
                            <Line type="monotone" dataKey="patients" stroke="#00CC00" strokeWidth={2} />
                        </LineChart>
                    </div>
                    <div className="chain">
                        <h1 style={{ marginBottom: "0" }}><FaStethoscope /></h1>
                        <h3 style={{ marginTop: "0" }}>{this.props.t('registered_practitioners')}</h3>
                        <h2>{this.state.totalPractitioners}</h2>
                        <LineChart width={480} height={280} data={this.state.practitioners}>
                            <Tooltip />
                            <XAxis dataKey="month" />
                            <Legend />
                            <Line type="monotone" dataKey="practitioners" stroke="#00CC00" strokeWidth={2} />
                        </LineChart>
                    </div>
                    <div className="chain">
                        <h1 style={{ marginBottom: "0" }}><FaShieldVirus /></h1>
                        <h3 style={{ marginTop: "0" }}>{this.props.t('registered_allergologist')}</h3>
                        <h2>{this.state.totalAllergologists}</h2>
                        <LineChart width={480} height={280} data={this.state.allergologists}>
                            <Tooltip />
                            <XAxis dataKey="month" />
                            <Legend />
                            <Line type="monotone" dataKey="allergologists" stroke="#00CC00" strokeWidth={2} />
                        </LineChart>
                    </div>
                    <div className="chain">
                        <h1 style={{ marginBottom: "0" }}><FaNotesMedical /></h1>
                        <h3 style={{ marginTop: "0" }}>{this.props.t('proposals')}</h3>
                        <h2>{this.state.totalProposals}</h2>
                        <LineChart width={480} height={280} data={this.state.proposals}>
                            <Tooltip />
                            <XAxis dataKey="month" />
                            <Legend />
                            <Line type="monotone" dataKey="proposals" stroke="#00CC00" strokeWidth={2} />
                        </LineChart>
                    </div>
                    <div className="chain">
                        <h1 style={{ marginBottom: "0" }}><FaStamp /></h1>
                        <h3 style={{ marginTop: "0" }}>{this.props.t('ops')}</h3>
                        <h2>{this.state.totalOps}</h2>
                        <LineChart width={480} height={280} data={this.state.ops}>
                            <Tooltip />
                            <XAxis dataKey="month" />
                            <Legend />
                            <Line type="monotone" dataKey="ops" stroke="#00CC00" strokeWidth={2} />
                        </LineChart>
                    </div>
                    <div className="stat" style={{ width: "calc(100% - 80px)" }}>
                        <h1 style={{ marginBottom: "0" }}><FaCode /></h1>
                        <h3 style={{ marginTop: "0" }}>{this.props.t('chain_info')}</h3>
                        <h4>{this.props.t('contract_id')} {">"} <Link to={`https://${NETWORK}.tzkt.io/${VIGI_CONTRACT}`} rel="noopener noreferrer" target="_blank">{VIGI_CONTRACT}</Link></h4>
                        <h4>{this.props.t('network')} {">"} Tezos-{NETWORK}</h4>
                        <div className="call2action" onClick={() => window.location.href = "/auth"}>{this.props.t('admin_access')}</div>
                    </div>
                    <Footer mv={true} />
                </content>
            </div>
        );
    }
}

export default withTranslation()(Stats);