class TokenService {
  async getLocalRefreshToken() {
    const user = JSON.parse(await localStorage.getItem('user'));
    return user.refresh_token;
  }

  async getLocalAccessToken() {
    const user = JSON.parse(await localStorage.getItem('user'));
    return user?.jwt_token;
  }

  async updateLocalAccessToken(token) {
    let user = JSON.parse(await localStorage.getItem('user'));
    user.jwt_token = token;
    await localStorage.setItem('user', JSON.stringify(user));
  }

  async setRefreshToken(token) {
    let user = JSON.parse(await localStorage.getItem('user'));
    user.refresh_token = token;
    await localStorage.setItem('user', JSON.stringify(user));
  }

  async getUser() {
    if (await localStorage.getItem('user')) return JSON.parse(await localStorage.getItem('user'));
    else window.location.href = '/';
  }

  async setUser(user) {
    if (!await localStorage.getItem('user')){
      await localStorage.setItem('user', JSON.stringify(user));
    }
  }

  async removeUser() {
    await localStorage.removeItem('user');
    window.location.href = '/';
  }
}

export default new TokenService();