import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Header from '../../layout/header';
import Footer from '../../layout/footer';
import { Link } from 'react-router-dom';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import SubscriptionService from '../../services/subscription_services';
import { SITE_KEY } from '../../constants';

// Images
import VIUSAL1 from '../../assets/img/vigi_01.png';
import VIUSAL2 from '../../assets/img/vigi_02.png';
import VIGI from '../../assets/img/vigi_logo.png';
import TRIAL from '../../assets/img/trial.jpg';
import CHU from '../../assets/img/CHU-montpellier.png';
import CODINSIGHT from '../../assets/img/codinsight_white.png';
import TEZOS from '../../assets/img/tezos.png';
import JDE from '../../assets/img/jde.png';
import ZDNET from '../../assets/img/zdnet.jpeg';
import FINYEAR from '../../assets/img/finyear.png';
import XTZNEWS from '../../assets/img/xtznews.jpeg';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: null,
            email: null,
            success: false,
            error: null,
            info: null,
        }
    }

    handleEmail = (e) => {
        this.setState({ email: e.target.value });
    }

    onVerifyCaptcha = (token) => {
        this.setState({ token })
    }

    subTrials = async () => {
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(this.state.email)) {
            this.setState({ error: 'invalid_email_syntax', info: null, success: false })
        } else {
            this.setState({ info: 'in_progress', error: null, success: false, loading: true });
            const resp = await SubscriptionService.subscribe(this.state.email, this.state.token)
            this.setState({ success: resp.status, info: null, error: !resp.status && resp.message, loading: false })
        }
    }

    render() {
        return (
            <div className="main">
                <Header />
                <content id="discover">
                    <img className="visual" src={VIUSAL1} alt="Vigicard app login" />
                    <img className="visual" style={{ marginLeft: "10px" }} src={VIUSAL2} alt="Vigicard app allergies" />
                    <div className="discover">
                        <img className="vigi" src={VIGI} alt="Vigi logo" />
                        <h1>{this.props.t('your_card')}</h1>
                        <p>
                            <strong>Vigicard</strong> {this.props.t('mobile_app')}
                        </p>
                        <p>
                            <strong>Vigicard</strong> {this.props.t('in_dev')} <strong>{this.props.t('2023_trials')}</strong>.
                        </p>
                        <p>
                            {this.props.t('is_dev')} <Link to={"https://www.chu-montpellier.fr/fr/"} target="_blank">CHU de Montpellier</Link> {this.props.t('and')} <Link to={"https://codinsight.com/"} target="_blank">Codinsight</Link>.
                        </p>
                        <div className="call2action" onClick={() => window.open("mailTo:contact@vigicard.fr")}>
                            {this.props.t('contact_us')}
                        </div>
                    </div>
                    <div className="benefits">
                        <h2>{this.props.t('unique_ads')}</h2>
                        <p><strong>Vigicard</strong> {this.props.t('desc_ads')}</p>
                        <div className="ads">+ {this.props.t('ad1')}</div>
                        <div className="ads">+ {this.props.t('ad2')}</div>
                        <div className="ads">+ {this.props.t('ad3')}</div>
                        <div className="ads">+ {this.props.t('ad4')}</div>
                        <div className="ads">+ {this.props.t('ad5')}</div>
                        <div className="ads">+ {this.props.t('ad6')}</div>
                        <div className="ads" id="trial">+ {this.props.t('ad7')}</div>
                    </div>
                    <div className="full">
                        <div className="trial" style={{ backgroundImage: `url('${TRIAL}')` }}></div>
                        <div className="participate">
                            <h1>{this.props.t('join_trials')}</h1>
                            <p>
                                {this.props.t('conducted_trials')} <strong>CHU de Montpellier</strong> {this.props.t('start_end_2023')}
                                <br />
                                {this.props.t('trials_validate')}
                            </p>
                            <p>
                                {this.props.t('trials_metric')}
                            </p>
                            <p>
                                <strong>{this.props.t('join_us')}</strong>
                            </p>
                            <hr />
                            <h3>{this.props.t('subscribe_trials')}</h3>
                            <div className="form-group">
                                {this.state.error && <p className="text-error">{this.props.t(this.state.error)}</p>}
                                {this.state.success && <p className="text-success">{this.props.t('trials_subscribed')}</p>}
                                <input type="email" placeholder={this.props.t('your_email')} onChange={this.handleEmail} />
                                {this.state.info ?
                                    <p>{this.props.t(this.state.info)}</p>
                                    :
                                    !this.state.success &&
                                    <button onClick={this.subTrials}>
                                        {this.props.t('subscribe')}
                                    </button>
                                }
                            </div>
                            <HCaptcha
                                sitekey={SITE_KEY}
                                theme={"dark"}
                                onVerify={this.onVerifyCaptcha}
                            />
                            <p id="contact">
                                <small>
                                    {this.props.t('about')}
                                </small>
                            </p>
                        </div>
                        <div className="contact">
                            <h2>{this.props.t('contact_us')}</h2>
                            <p>{this.props.t('contact_email')}</p>
                            <div className="call2action" onClick={() => window.open("mailTo:contact@vigicard.fr")}>
                                {this.props.t('send_mail')}
                            </div>
                        </div>
                        <div className="team">
                            <h2>{this.props.t('collaboration')}</h2>
                            <p><strong>Vigicard</strong> {this.props.t('specialist')}</p>
                            <center>
                                <img src={CHU} className="partner" alt="Logo CHU Montpellier" onClick={() => window.open("https://www.chu-montpellier.fr/fr/")} />
                                <img src={CODINSIGHT} className="partner" alt="Logo Codinsight" onClick={() => window.open("https://codinsight.com")} />
                                <img src={TEZOS} className="partner" alt="Logo Tezos" onClick={() => window.open("https://tezos.com")} />
                            </center>
                        </div>
                        <div className="contact">
                            <h2>{this.props.t('article_press')}</h2>
                            <p>{this.props.t('speak_about_us')}</p>
                            <div className="box_press">
                                <div className="visual_press" style={{ backgroundImage: `url('${JDE}')` }}></div>
                                <div className="desc_press">
                                    Le projet montpelliérain Vigicard reçoit le soutien de la Fondation Tezos
                                </div>
                                <a href="https://www.lejournaldesentreprises.com/occitanie/breve/le-projet-montpellierain-vigicard-recoit-le-soutien-de-la-fondation-tezos-2075183" target="_blank" rel="noreferrer">
                                    {this.props.t('read_more')}
                                </a>
                            </div>
                            <div className="box_press">
                                <div className="visual_press" style={{ backgroundImage: `url('${ZDNET}')` }}></div>
                                <div className="desc_press">
                                    Le projet Vigicard trace les allergies médicamenteuses par la blockchain
                                </div>
                                <a href="https://www.zdnet.fr/actualites/le-projet-vigicard-trace-les-allergies-medicamenteuses-par-la-blockchain-39962594.htm" target="_blank" rel="noreferrer">
                                    {this.props.t('read_more')}
                                </a>
                            </div>
                            <div className="box_press">
                                <div className="visual_press" style={{ backgroundImage: `url('${FINYEAR}')` }}></div>
                                <div className="desc_press">
                                    Blockchain Tezos : un projet healthtech pour tracer les allergies médicamenteuses
                                </div>
                                <a href="https://www.finyear.com/Blockchain-Tezos-un-projet-healthtech-pour-tracer-les-allergies-medicamenteuses_a50108.html" target="_blank" rel="noreferrer">
                                    {this.props.t('read_more')}
                                </a>
                            </div>
                            <div className="box_press">
                                <div className="visual_press" style={{ backgroundImage: `url('${XTZNEWS}')` }}></div>
                                <div className="desc_press">
                                    Projet Vigicard, l’appli pour suivre les allergies médicamenteuses sur Tezos
                                </div>
                                <a href="https://xtz.news/fr/non-classifiee/projet-vigicard-lappli-pour-suivre-les-allergies-medicamenteuses-sur-tezos/" target="_blank" rel="noreferrer">
                                    {this.props.t('read_more')}
                                </a>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </content>
            </div>
        );
    }
}

export default withTranslation()(Home);